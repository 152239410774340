import loggedreducer from './logged';
import cartCounter from './cartCounter';
import {ProductReducer,AddcartReducer } from './ProductReducer';
import { LoggedUser } from '../actions';
import {combineReducers} from 'redux';


const allReducers = combineReducers(
    {
        loggedreducer,
        cartCounter,
        ProductReducer,
        AddcartReducer,
        LoggedUser,
    }
);

export default allReducers;