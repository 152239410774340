import React, { useState, useEffect }  from 'react';
import './css/Cart.css';
import Divider from '@material-ui/core/Divider';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import { useSelector,useDispatch } from 'react-redux';
import {LoggedUser} from './actions';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


function Login() {

    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [validation, setValid] = React.useState(false);
    const [validationp, setValidp] = React.useState(false);
    const [email, setEmail] = useState('');
    const [password, setPass] = useState('');
    const [count, setCount] = useState(0);
    const[loggeduser,setLog] = useState('');
    const [loader, setLoader] = React.useState(false);
    // const handle = () => {
    //     localStorage.setItem('Name', name);
    //     localStorage.setItem('Password', pwd);
    //  };
    //  const remove = () => {
    //     localStorage.removeItem('Name');
    //     localStorage.removeItem('Password');
    //  };


    const login = () => {
       
        if(email == "")
        {
            
            setValid(true);
        }
        else if(password == "")
        {
            setValidp(true);
        }
        else
        {
            setLoader(true);
            axios.post(`https://www.wattabyte.com/PMC/api/login`, { email,password })
            .then(res => {
              console.log(res);
              
              if(res.data['error'] == 1)
              {
                setLoader(false);
                setOpen(true);
              }
              else
              {
                setLoader(false);
                setLog(res.data['user_id']);
                localStorage.setItem('user_id', res.data['user_id']); 
                window.location.href ="/";
              }
            })
        }

    }

      
    useEffect(() => {
        
    }, [])

   

    
    return (
        <div className="login-page">        
            <div class="flex items-center mt-8 bg-white dark:bg-gray-900">
                <div class="container mx-auto">
                    <div class="max-w-md mx-auto my-10">
                       
                    <Collapse in={open}>
                        <Alert severity="error" 
                        action={

                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        Invalid Credentials!
                        </Alert>
                    </Collapse>

                    <Collapse in={validation}>
                        <Alert severity="error" 
                        action={

                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setValid(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        Please Enter a Valid Email Id
                        </Alert>
                    </Collapse>

                    <Collapse in={validationp}>
                        <Alert severity="error" 
                        action={

                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setValidp(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        Please Enter a Valid Password !
                        </Alert>
                    </Collapse>
                    <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loader}  
                    >
                    <CircularProgress color="inherit" />
                    </Backdrop>

                        <div class="text-center">
                            <h1 class="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">Sign in</h1>
                            <p class="text-gray-500 dark:text-gray-400">Sign in to access your account</p>
                        </div>
                        <div class="m-7">
                            <form action="">
                                <div class="mb-6">
                                    <label for="email" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Email Address</label>
                                    <input type="email" name="email" id="email" placeholder="you@company.com" onChange={(e) => setEmail(e.target.value)} class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
                                </div>
                                <div class="mb-6">
                                    <div class="flex justify-between mb-2">
                                        <label for="password" class="text-sm text-gray-600 dark:text-gray-400">Password</label>
                                        {/* <a href="#!" class="text-sm text-gray-400 focus:outline-none focus:text-indigo-500 hover:text-indigo-500 dark:hover:text-indigo-300">Forgot password?</a> */}
                                    </div>
                                    <input type="password" name="password" id="password" onChange={(e) => setPass(e.target.value)} placeholder="Your Password" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
                                </div>
                                <div class="mb-6">
                                    {/* <button  type="button" class="btn-check w-full px-3 py-2 text-white bg-indigo-500 rounded-md focus:bg-indigo-600 focus:outline-none">Sign in</button> */}
                                    <Button onClick={login} class="btn-check w-full px-3 py-2 text-white bg-indigo-500 rounded-md  focus:outline-none"  variant="contained">Sign In</Button>
                                </div>
                                   <Link to="/register"> <p class="text-sm text-center text-gray-800">Don&#x27;t have an account yet? <a href="#!" class="text-indigo-400 focus:outline-none focus:underline focus:text-indigo-500 dark:focus:border-indigo-800">Sign up</a>.</p> </Link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login

