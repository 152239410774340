import React, { useState, useEffect } from 'react';
import './css/Header.css';

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SearchIcon from '@material-ui/icons/Search';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import { Link } from "react-router-dom";

function Header() {

    const [user_id, setUser] = useState('');
    const user = localStorage.getItem("user_id");
    const [validation, setValid] = useState(false);

    useEffect(() => {
        if(user == null)
        {
            setUser('');
            setValid(false)
        }
        else
        {
            setUser(user);
            setValid(true)
        }
        
    }, [])

    const logout = () => {
        localStorage.removeItem("user_id");
        window.location.reload();
    }

    
    return (
        <div className="header shadow-md">
            <div className="header__body">
              
                    <div className="header__logo">
                    <Link to="/"><img class="" alt="Raidan- Authentic Arabian Restaurant" width="200px"  src="https://pickmycaterer.com/wp-content/uploads/2021/02/log1.png" /></Link>
                    </div>
                
                <div className="header__icons">
                   
                 
                    <Link to="/cart" className="header__icon">
                        <ShoppingBasketOutlinedIcon />
                        <p>Cart</p>
                    </Link>
                    
                    <Link to={{ pathname: "https://pickmycaterer.com/faqs/" }} target="_blank" className="header__icon">
                        <FeedbackOutlinedIcon />
                        <p>FAQ's</p>
                    </Link>
                   
                    <Link to={{ pathname: "https://pickmycaterer.com/contact/" }} target="_blank"  className="header__icon">
                        <p>Contact</p>
                    </Link>

                    {!validation ?
                              <Link to="/login" className="header__icon">
                                <PersonOutlineIcon />
                                <p>Sign In</p>
                            </Link>
                   :
                    <Link to="" onClick={logout} className="header__icon">
                        <PersonOutlineIcon />
                        <p>Log Out</p>
                    </Link>
                    }
                </div>
            </div>
            
        </div>
    )
}

export default Header
