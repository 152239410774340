import React, { useState, useEffect } from 'react';
import './css/Success.css';
import { Divider , Button } from '@material-ui/core';
import Confetti from 'react-confetti';
import { Link } from "react-router-dom";
 
function Success() {
    useEffect(() => {

        // console.log(this.props.location.state.order_id);

    }, [])

   return (
       <div className="success mt-40">
 
           <img className="success__image mb-5" src="https://freepngimg.com/save/27889-green-tick/416x341" />
          
           <div className="flex justify-center">
           {/* <Confetti
               drawShape={ctx => {
                   ctx.beginPath()
                   for(let i = 0; i < 22; i++) {
                   const angle = 0.35 * i
                   const x = (0.2 + (1.5 * angle)) * Math.cos(angle)
                   const y = (0.2 + (1.5 * angle)) * Math.sin(angle)
                   ctx.lineTo(x, y)
                   }
                   ctx.stroke()
                   ctx.closePath()
               }}
           /> */}
               <p className="text-4xl tracking-wider mb-5">Success</p>
           </div>
           <div className="main__para">
               <p className="text-center mb-5">Thank You for placing an Order with Pickmycaterer </p>
               <p className="text-center mb-5">Our team will review your Order and get back you with a quotation within 24 Hours. </p>
               <Link to="/"><button className="bg-transparent flex m-auto hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">Home</button></Link>
           </div>
 
          
       </div>
 
      
   )
}
 
export default Success