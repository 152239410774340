import React, { useState , useEffect } from 'react';
import './css/Eat.css';
import { useSelector,useDispatch } from 'react-redux';
import {cartincrement} from './actions';
import { Link } from "react-router-dom";
import axios from 'axios';
import {Addproduct} from './actions/Addproduct';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';


function Eat() {
 
    const counter = useSelector(state => state.cartCounter);
    const dispatch = useDispatch();
    const products = useSelector((state) => state.ProductReducer.products);
    const [open, setOpen] = React.useState(false);
    const [Invalid, setInvalid] = React.useState(false);

    const addtocart =({menu_id}) => {
       
        const item_id = menu_id;
        const user_id = localStorage.getItem("user_id");
        
        if(user_id == null)
        {
            alert("Please Login to Add");
            window.location.href="login";
        }
        else 
        {
            
            axios.post(`https://www.wattabyte.com/PMC/api/add_to_cart`, {item_id,user_id})
            .then(res => {
              console.log(res.data);
              if(res.data['is_error'] == 0)
              {
                setOpen(true);
              }
              else
              {
                setInvalid(true);
              }
            })
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };



        return (
           
                <div className="">
                        <div class="">
                                <Snackbar open={open} autoHideDuration={2000}  onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right'}}>
                                    <Alert variant="filled" severity="success" 
                                    action={

                                            <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setOpen(false);
                                            }}
                                            >
                                            <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                        sx={{ mb: 2,width: '100%',m:'auto' }}
                                        >
                                            Food Item Added to Cart
                                    </Alert>
                                </Snackbar>

                                <Snackbar open={Invalid} autoHideDuration={2000}  onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right'}}>
                                    <Alert variant="filled" severity="error" 
                                    action={

                                            <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setInvalid(false);
                                            }}
                                            >
                                            <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                        sx={{ mb: 2,width: '100%',m:'auto' }}
                                        >
                                            Food Item Already There !
                                    </Alert>
                                </Snackbar>
                        </div>
                        <div className="parentdiveat">

                            { products.map((eats) => ( 
                                <div className="eat m-4 bg-white shadow-md rounded-md" key={eats.menu_id}>
                                
                                    { eats.image !== "" ? 
                                    <img className="eat__image" alt="" src={eats.image} /> :
                                    <img className="eat__image" alt="" src="https://i.stack.imgur.com/y9DpT.jpg" /> 
                                    }
                                    <div className="p-2">
                                        <p className="eat__name">{eats.menu_name}</p>
                                        <div className="flex justify-between">
                                            
                                            <p>{eats.category}</p>
                                            <div className="inner-img">
                                                <img class="" alt="Raidan- Authentic Arabian Restaurant" width="20px"  src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Indian-vegetarian-mark.svg/768px-Indian-vegetarian-mark.svg.png" />
                                            </div>
                                        </div>
                                        
                                    </div>
                                
                                    <div className="inner-text p-2">
                                    <button class="add-btn px-20 py-1.5 text-sm text-black-600 font-semibold  border border-purple-200 " onClick={ () => addtocart({menu_id: eats.menu_id}) } >Add</button>
                                    </div>
                                </div>
                            ))}   
                    </div>
                </div>
             
        )


    
}



export default Eat
