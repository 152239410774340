
import { ActionTypes } from "../actions/constants/Action-type";

const initialState = {
    products:[
      
    ],
};

const cartitems = {
    cart:[],
};

export const ProductReducer = (state=initialState, {type, payload}) => {
    switch (type)
    {
        case ActionTypes.SET_PRODUCTS:
            return {...state, products:payload};
       
        default:
            return state;
    }
};

export const AddcartReducer = (state=cartitems, {type, payload}) => {
    switch (type)
    {
        case ActionTypes.ADD_PRODUCTS:
            return {...state,
                cart:[...state.cart, payload]};
       
        default:
            return state;
    }
}


export default ProductReducer;