import React, { useState, useEffect }  from 'react';
import './css/Cart.css';
import Divider from '@material-ui/core/Divider';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { Link } from "react-router-dom";
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';



function Register() {
    const [count, setCount] = useState(0);
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [passwordc, setPassc] = useState('');
    const [password, setPass] = useState('');
    const [loader, setLoader] = React.useState(false);

    const login = () => {
        
        if(name == "")
        {
            alert("name Cannot be Empty")
        }
        else if(mobile.length < 10)
        {
            alert("Please Enter a valid Mobile Number")
        }
        else if(email == "")
        {
            alert("Email Cannot be Empty")
        }
        else if(password == "")
        {
            alert("Password Cannot be Empty")
        }

        else if(password != passwordc)
        {
            alert("Password Mismatch!")
        }
        else
        {
            setLoader(true);
            axios.post(`https://www.wattabyte.com/PMC/api/signup`, {name,email,mobile,password})
            .then(res => {
              console.log(res);
              
              if(res.data['error'] == 1)
              {
                alert("Already Registered User");
                setLoader(false);
              }
              else
              {
                 alert("successfully Regsitered Please Login to continue"); 
                 window.location.href="login";
              }
            })
           
        }
    }
    
    return (
        <div className="login-page">        
            <div class="flex items-center mt-8 bg-white dark:bg-gray-900">
                <div class="container mx-auto">
                    <div class="max-w-md mx-auto my-10">
                    <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loader}  
                    >
                    <CircularProgress color="inherit" />
                    </Backdrop>
                        <div class="text-center">
                            <h1 class="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">Sign Up</h1>
                            <p class="text-gray-500 dark:text-gray-400">Sign in to access your account</p>
                        </div>
                        <div class="m-7">
                            <form action="">
                                <div class="mb-6">
                                    <label for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Name</label>
                                    <input type="text" name="name" id="name" onChange={(e) => setName(e.target.value)} placeholder="your name" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
                                </div>
                                <div class="mb-6">
                                    <label for="email" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Email Address</label>
                                    <input type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} placeholder="you@company.com" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
                                </div>
                                <div class="mb-6">
                                    <label for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Mobile</label>
                                    <input type="number" name="name" id="name" onChange={(e) => setMobile(e.target.value)} placeholder="your Mobile" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
                                </div>
                                <div class="mb-6">
                                    <div class="flex justify-between mb-2">
                                        <label for="password" class="text-sm text-gray-600 dark:text-gray-400">Password</label>
                                        
                                    </div>
                                    <input type="password" name="password" id="password" onChange={(e) => setPassc(e.target.value)} placeholder="Your Password" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
                                </div>
                                <div class="mb-6">
                                    <div class="flex justify-between mb-2">
                                        <label for="password" class="text-sm text-gray-600 dark:text-gray-400">Confirm Password</label>
                                        
                                    </div>
                                    <input type="password" name="password" id="password" onChange={(e) => setPass(e.target.value)} placeholder="Confirm Password" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
                                </div>
                                <div class="mb-6">
                                    <button onClick={login} type="button" class="btn-check w-full px-3 py-2 text-white rounded-md  focus:outline-none">Sign in</button>
                                
                                </div>
                                <Link to="/login"> <p class="text-sm text-center text-gray-800">Already Have an account? <a href="#!" class="text-indigo-400 focus:outline-none focus:underline focus:text-indigo-500 dark:focus:border-indigo-800">Sign in</a>.</p> </Link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register
