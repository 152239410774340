import { ActionTypes } from "./constants/Action-type"


export const Addproduct = (products) => {
    return {
      type: ActionTypes.ADD_PRODUCTS,
      payload: products,
    };
  };
  
  export const Removeproduct = (products) => {
    return {
      type: ActionTypes.REMOVE_PRODUCT,
      payload: products,
    };
  };

  export const Setproduct = (products) => {
    return {
      type: ActionTypes.SET_PRODUCTS,
      payload: products,
    };
  };