import './css/App.css';
import Header from './Header';
import Body from './Body';
import Likes from './Likes';
import Footer from './Footer';
import Cart from './Cart';
import Login from './Login';
import Register from './Register';
import Success from './Success';
import Navbar from './Navbar';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// import {createStore} from 'redux';


// //Actions
// const increment = () => {
//   return{
//     type:'INCREMENT',
//   };
// }

// const decrement = () => {
//   return{
//     type:'DECREMENT',
//   };
// }

// // Reducer
// const counter = (state=0, action) => {
//   switch (action.type) {
//     case 'INCREMENT':
//       return state+1;
//     case 'DECREMENT':
//       return state-1;
//   }
// };

// let store = createStore(counter);

// store.subscribe(() => console.log(store.getState));


// store.dispatch(increment());




function App() {
  return (
    <Router>
        
      <div className="App">
        <Header />
        <Switch>
          <Route exact path="/">
            <Body />
          </Route>
          <Route exact path="/likes">
            <Likes />
          </Route>
          <Route exact path="/cart">
            <Cart />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/register">
            <Register />
          </Route>
          <Route exact path="/success">
            <Success />
          </Route>
          <Route exact path="/navbar">
            <Navbar />
          </Route>
        </Switch>
      </div>

    </Router>
    
  );
}

export default App;
