import React , { useState , useEffect } from 'react';
import './css/Likes.css';
import Eat from './Eat';
import axios from 'axios';
import { Link } from "react-router-dom";

function Likes() {

    const[eats,setEats] = useState([]);

    useEffect(() => {
       
        async function menu(){
            const request = await axios.get('https://ohieo.com/App/menu_items' )
            //console.log(request.data.result)
            setEats(request.data.result)
        }
        menu();
    }, [])


    return (
        <div className="likes">
            <p className="text-2xl tracking-wide text-center mb-5">No liked Items</p>
            
        </div>
    )
}

export default Likes
