import React , { useState , useEffect } from 'react';
import './css/Body.css'
import Eat from './Eat';
import { useSelector,useDispatch } from 'react-redux';
import axios from 'axios';
import {Setproduct} from './actions/Addproduct';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

function Body() {

    const[eats,setEats] = useState([]);
    const[category,setCategory] = useState([]);

    const products = useSelector((state) => state.ProductReducer.products);
    const [skelet, setSklt] = useState(true);
    // const { id, title, img} = products[0];
    // console.log(img);
    const dispatch = useDispatch();

    useEffect(() => {

        document.title = "Pickmycaterer";
        async function menu(){
            const request = await axios.get('https://wattabyte.com/PMC/api/menu' )
            dispatch(Setproduct(request.data.menu));
            setEats(request.data.menu);
            
        }

        async function category(){
            const request = await axios.get('https://wattabyte.com/PMC/api/category' )
            console.log(request.data.categories)
            setCategory(request.data.categories);
            setSklt(false);
        }

        menu();
        category();


    }, [])

    const sub_category = ({subcategory}) => {
        const arr = eats.filter((item) => item.category == subcategory);
        dispatch(Setproduct(arr));
       
        console.log(arr);

    }

  
    return (
        <div className="body">  

            <div className="body__nav">
            <div>
                <link rel="stylesheet" href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css" />

                    <div class="min-h-screen flex flex-row bg-white pl-4">
                    <div class="flex flex-col bg-white  overflow-hidden ">
                    
                        <ul class="flex flex-col py-4">
                        {skelet &&
                                <Stack spacing={1}>
                                    <Skeleton variant="text" width={230} height={40} />
                                    <Skeleton variant="text" width={230} height={40} />
                                    <Skeleton variant="text" width={230} height={40} />
                                    <Skeleton variant="text" width={230} height={40} />
                                    <Skeleton variant="text" width={230} height={40} />
                                    <Skeleton variant="text" width={230} height={40} />
                                    <Skeleton variant="text" width={230} height={40} />
                                    <Skeleton variant="text" width={230} height={40} />
                                    <Skeleton variant="text" width={230} height={40} />
                                    <Skeleton variant="text" width={230} height={40} />
                                    <Skeleton variant="text" width={230} height={40} />
                                    <Skeleton variant="text" width={230} height={40} />
                                    <Skeleton variant="text" width={230} height={40} />
                                    <Skeleton variant="text" width={230} height={40} />
 
                                </Stack>
                        }
                        { category.map((cat) => (
                            <li className="li_item">
                                <a href="#" onClick={ () => sub_category({subcategory: cat.category_name}) }  class="flex flex-row items-center h-8 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-800 hover:text-gray-800">
                                    <span class="text-sm font-medium">{cat.category_name}</span>
                                </a>
                            </li>
                             ))}   
                        </ul>
                    </div>
                    </div>
                    
                </div>
            </div>

            <div className="body__eats">

          
                <div className="flex">
                    <Eat />
                </div>
                
            </div>

            
        </div>
    )
}

export default Body
