export const cartincrement = () => {
    return {
        type : 'ADD'
    };  
}

export const loginvalidation = () => {
    return {
        type: 'LOGGED_IN'
    }
}

export const LoggedUser = (userid) => {
    return {
        type: 'LOGGED_USER',
        payload:userid,
    }
}