import React, { useState , useEffect } from 'react';
import './css/Cart.css';
import Divider from '@material-ui/core/Divider';
import Button from '@mui/material/Button';
import { useSelector,useDispatch } from 'react-redux';
import axios from 'axios';
import useForceUpdate from 'use-force-update';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


function Cart() {

    const[eats,setEats] = useState([]);
    const [no_of_guests, setGuest] = useState('');
    const [event_address, setaddress] = useState('');
    const [p_landmark, setLand] = useState('');
    const [eventdate, setEvent] = useState('');
    const [p_city, setCity] = useState('');
    const [p_pincode, setPin] = useState('');
    const [skelet, setSklt] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [loader, setLoader] = React.useState(false);
    const [cartcount, setCartcount] = React.useState('');
    const forceUpdate = useForceUpdate();

    const addproduct = (e) => {
        e.preventDefault();
        alert("Please Add some products to checkout")
    }
    const cart = useSelector((state) => state.AddcartReducer.cart);
    const products = useSelector((state) => state.ProductReducer.products);
    const listcart = cart.map((cart) => {
        const product_list = products.map((product) =>{
            if( cart == product.food_item_id)
            {
                console.log(product);
            }
        })
    })

    const create_order = (e) => {

       e.preventDefault();
       setLoader(true);
       const user_id = localStorage.getItem("user_id");
       var event_date = eventdate;
       var guests = no_of_guests;
       var special_mention = "Nothing"; 
       var address = event_address;
       var city = p_city;
       var pincode = p_pincode;
       var landmark = p_landmark;
     
       if(guests < 5)
       {
           alert("No of Guests Should be Minimum 5");
       }

       else if(address == "")
       {
           alert("Please Enter a valid Address");
       }

       else if(landmark == "")
       {
           alert("Please Enter a valid Landmark");
       }
       
       else if(event_date == "")
       {
           alert("Please Add Event Date");
       }

       else if(city == "")
       {
           alert("Please Enter a Valid City");
       }

       else if(pincode == "")
       {
           alert("Please Enter a Valid Pincode");
       }

       else 
       {
            axios.post(`https://wattabyte.com/PMC/api/create_order`, {user_id,event_date,guests,special_mention,address,city,pincode,landmark})
            .then(res => {
            console.log(res); 
                if(res.data['is_error'] == 0)
                {
                    setLoader(false);
                    window.location.href="/success";
                }
                else
                {
                    alert("Could't Create your order");
                }
            })
       }

    }

    const removecart = ({menu_id}) => {
        const user_id = localStorage.getItem("user_id");
        var item_id = menu_id;
        axios.post(`https://wattabyte.com/PMC/api/remove_cart`, {user_id,item_id})
        .then(res => {
          console.log(res); 
          setOpen(true);
        //   window.location.reload();
        })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    useEffect(() => {

        async function menu(){
            const user_id = localStorage.getItem("user_id");
            const request =await axios.post(`https://www.wattabyte.com/PMC/api/get_cart`, {user_id})
                console.log(request.data.cart_item);
                setEats(request.data.cart_item);
                setCartcount(request.data.cart_item.length)
                setSklt(false);
        }
        menu();

    }, [eats])

   console.log(cartcount);

    return (
        <div className="cart flex flex-row">

                        <div class="">

                                <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right'}}>
                                    <Alert variant="filled" severity="error" 
                                    action={

                                            <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setOpen(false);
                                            }}
                                            >
                                            <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                        sx={{ mb: 2,width: '100%',m:'auto' }}
                                        >
                                            Food Item Removed Successfully
                                    </Alert>
                                </Snackbar>

                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={loader}  
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                        </div>
            
            <div className="cart__form flex-2">
               
                <p className="tracking-wide">Booking Information</p>

                <form class="w-full max-w-2xl mt-10">
                    <div class="flex flex-wrap -mx-3 mb-6">
                       
                        <div class="w-full md:w-1/2 px-3">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                No of Guests
                            </label>
                            <input class="border rounded w-full py-2 px-3 text-grey-darker" onChange={(e) => setGuest(e.target.value)} id="grid-password" type="number" placeholder="No of guest" />
                        </div>
                    </div>
                   
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                            Full Address
                        </label>
                        <input class="border rounded w-full py-2 px-3 text-grey-darker" onChange={(e) => setaddress(e.target.value)} id="grid-password" type="text" placeholder="#66 , 2nd Cross , BTM 2nd Stage" />
                        <p class="text-gray-600 text-xs italic">Please enter your complete address</p>
                        </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                            Landmark
                        </label>
                        <input class="border rounded w-full py-2 px-3 text-grey-darker" onChange={(e) => setLand(e.target.value)} id="grid-first-name" type="text" placeholder="Opposite to Dominos" />
                        
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Event Date
                        </label>
                        <input class="border rounded w-full py-2 px-3 text-grey-darker" onChange={(e) => setEvent(e.target.value)} id="grid-last-name" type="date" placeholder="Doe" />
                        </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                            City
                        </label>
                        <input class="border rounded w-full py-2 px-3 text-grey-darker" onChange={(e) => setCity(e.target.value)} id="grid-first-name" type="text" placeholder="Bengaluru" />
                        
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Pincode
                        </label>
                        <input class="border rounded w-full py-2 px-3 text-grey-darker" onChange={(e) => setPin(e.target.value)} id="grid-last-name" type="number" placeholder="5600XX" />
                        </div>
                    </div>
                    { cartcount === 0 ?
                       <div class="flex flex-wrap -mx-3 mb-6 ml-1 ">
                             <Button variant="contained" disabled>
                                Add some Products to Checkout
                            </Button>
                         </div>
                        :
                   
                    <div class="flex flex-wrap -mx-3 mb-6 ">
                        <button onClick={create_order} class="btn-check px-20 py-1.5 text-sm text-white font-semibold ml-3 border border-purple-200">Confirm Order</button>
                    </div>
                         
                        }
                </form>

            </div>

            <div className="cart__info flex-1  p-2">
                <div class="flex justify-center lg:justify-end">
                        <div class="border rounded-md max-w-md w-full px-4 py-3 parent_cart">
                            <div class="flex items-center justify-between ">
                                <h3 class="text-gray-700 font-medium">Total Items ({cartcount})</h3>
                               
                            </div>
                            {skelet &&
                                <Stack spacing={1}>
                                    <Skeleton variant="text" width={230} height={70} />
                                    <Skeleton variant="rectangular" width={230} height={70} />
                                </Stack>
                            }

                            { cartcount === 0 ?
                                <div> <h3 class="text-red-500 text-center text-xl text-bold m-2"> Your cart is Empty! </h3></div>
                                :
                                <div> </div>
                            }
                                { eats.map((eat) => ( 
                                    
                                    <div class="flex justify-between mt-6 " key={eat.menu_id}>
                                        <div class="flex">
                                            <img class="h-20 w-20 object-cover rounded" src={eat.image} alt="" />
                                            <div class="mx-3">           
                                                <h3 class="text-sm text-gray-600">{eat.menu_name}</h3>
                                                <div class="flex items-center mt-2">
                                                    <Button variant="text" value={eat.menu_id} onClick={ () => removecart({menu_id: eat.menu_id}) }> <span class="text-red-500 mx-2"> Remove</span> </Button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>

                                ))}   
                           
                            
                        </div>
                    </div>
                            <div className="mt-2">
                                <Divider />
                                <div className="flex flex-row m-1 mb-2 justify-between">
                                    <p className="flex-1">Additional Information</p>
                                   
                                </div>
                                <div className="flex flex-row m-1 justify-between">
                                    <p  className="flex-1"></p>
                                    
                                </div>
                            </div>
                
            </div>
        </div>
    )
}

export default Cart
